<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
        ออเดอร์
        <v-icon>mdi-chevron-right</v-icon>
        ออเดอร์ทั้งหมด
      </v-card-title>
    </v-card>

    <!-- ค้นหา -->
    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="pb-2">
          <v-col class="pb-1" cols="6">
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2"> คลัง : </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-autocomplete
                  v-model="search.warehouse"
                  placeholder="กรุณาเลือกคลัง"
                  :items="warehouseList_filter"
                  item-text="name"
                  item-value="id"
                  outlined
                  required
                  return-object
                  hide-details
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2"> ร้านค้า : </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-autocomplete
                  v-model="search.shop"
                  placeholder="กรุณากรอกร้านค้า"
                  :items="search.shopList"
                  item-text="name"
                  item-value="id"
                  outlined
                  required
                  return-object
                  hide-details
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2"> สถานะ : </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-select
                  v-model="search.shippingStatus"
                  :items="search.shippingStatusList"
                  @input="CheckSearchDateTime(search.shippingStatus.id)"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense
                  hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2"> วันที่สร้าง : </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      ref="menuStartDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateStart"
                          outlined
                          readonly
                          label="เริ่มต้น"
                          prepend-inner-icon="mdi-calendar"
                          @blur="startDate = parseDate(dateStart)"
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          clearable
                          :disabled="disableDate"
                          @click:clear="dateStart = null"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        @input="menuStartDate = false"
                        @change="
                          (dateStart = formatDate(startDate)),
                            CheckDateTime(0, startDate, endDate)
                        "
                        no-title
                        :allowed-dates="allowedDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      ref="menuEndDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateEnd"
                          outlined
                          readonly
                          label="สิ้นสุด"
                          prepend-inner-icon="mdi-calendar"
                          @blur="endDate = parseDate(dateEnd)"
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          clearable
                          :disabled="disableDate"
                          @click:clear="dateEnd = null"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        @input="menuEndDate = false"
                        @change="
                          (dateEnd = formatDate(endDate)),
                            CheckDateTime(0, startDate, endDate)
                        "
                        no-title
                        :allowed-dates="allowedDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2"> วันที่แพ็ค : </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      ref="menuStartDate2"
                      v-model="menuStartDate2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateStart2"
                          outlined
                          readonly
                          label="เริ่มต้น"
                          prepend-inner-icon="mdi-calendar"
                          @blur="startDate2 = parseDate(dateStart2)"
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          clearable
                          :disabled="disableDate2"
                          @click:clear="dateStart2 = null"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate2"
                        @input="menuStartDate2 = false"
                        @change="
                          (dateStart2 = formatDate(startDate2)),
                            CheckDateTime(1, startDate2, endDate2)
                        "
                        no-title
                        :allowed-dates="allowedDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      ref="menuEndDate2"
                      v-model="menuEndDate2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateEnd2"
                          outlined
                          readonly
                          label="สิ้นสุด"
                          prepend-inner-icon="mdi-calendar"
                          @blur="endDate2 = parseDate(dateEnd2)"
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          clearable
                          :disabled="disableDate2"
                          @click:clear="dateEnd2 = null"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate2"
                        @input="menuEndDate2 = false"
                        @change="
                          (dateEnd2 = formatDate(endDate2)),
                            CheckDateTime(1, startDate2, endDate2)
                        "
                        no-title
                        :allowed-dates="allowedDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="2"> ขนส่ง : </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-select
                  v-model="search.expressCompany"
                  :items="search.expressCompanyList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense
                  hide-details
                  outlined
                  single-line
                >
                  <template slot="selection" slot-scope="data">
                    <img
                      v-if="data.item.logoPicSmall != null"
                      :src="data.item.logoPicSmall"
                      class="mr-2"
                      style="width: 60px"
                    />
                    {{ length60(data.item.name) }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <img
                      v-if="data.item.logoPicSmall != null"
                      :src="data.item.logoPicSmall"
                      class="mr-2"
                      style="width: 60px"
                    />
                    {{ length60(data.item.name) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="pb-1" cols="6">
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="3"> ช่องทาง : </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-select
                  v-model="search.MarketPlaceSource"
                  :items="search.MarketPlaceSourceList"
                  item-value="uniqueID"
                  item-text="name"
                  persistent-hint
                  return-object
                  dense
                  hide-details
                  outlined
                  clearable
                  multiple
                  placeholder="คลิก, เพื่อค้นหาหลายรายการ"
                >
                  <template v-slot:item="{ item }">
                    <v-icon
                      v-if="
                        search.MarketPlaceSource !== null &&
                        search.MarketPlaceSource.includes(item)
                      "
                      color="primary"
                      class="mr-3"
                    >
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else class="mr-3"> mdi-checkbox-blank-outline </v-icon>
                    <img :src="item.logo" width="20px;" />
                    <div class="ml-2">{{ item.name }}</div>
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="3"> เลขออเดอร์ : </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-text-field
                  :value="search.orderId"
                  @change="(value) => (search.orderId = Trim_value(value))"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                  placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="3"> เลขอ้างอิง : </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-text-field
                  :value="search.referenceNo"
                  @change="(value) => (search.referenceNo = Trim_value(value))"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                  placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="3"> TrackingNo : </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-text-field
                  :value="search.trackingNo"
                  @change="(value) => (search.trackingNo = Trim_value(value))"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                  placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="pb-2">
              <v-col class="pb-1" cols="12" md="4" lg="3"> </v-col>
              <v-col class="pb-1" cols="12" md="8">
                <v-btn
                  :elevation="1"
                  class="pa-2 mt-1"
                  color="primary"
                  dark
                  @click="SearchOrder(limits, 0, search, 1)"
                >
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>
                <v-btn
                  :elevation="1"
                  class="pa-2 ml-2 mt-1"
                  outlined
                  color="primary"
                  @click="ResetSearch()"
                >
                  <v-icon size="14" class="pr-1">fa-solid fa-rotate</v-icon> รีเช็ต
                </v-btn>
                <vue-excel-xlsx
                  id="GetExcel"
                  v-show="false"
                  :data="datasetExcel"
                  :columns="headerExcel"
                  :file-name="ExcelName"
                  :sheet-name="'shee1'"
                >
                </vue-excel-xlsx>
                <v-btn
                  :elevation="1"
                  outlined
                  color="success"
                  class="pa-2 ml-2 mt-1"
                  @click="ExportExcel()"
                >
                  <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                  <span class="fn-12"> Export Excel </span>
                  <span v-if="loadingExcel != 0">({{ loadingExcel }}%)</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :elevation="1"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="ma-3 mb-5 ml-0 fn-14"
                >
                  <span class="pl-1">ดำเนินการ</span>
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <!-- <v-list-item @click="SumaryProduct(search)">
                  <v-list-item-title>กรองสินค้าที่เลือก</v-list-item-title>
                </v-list-item>
                <v-list-item @click="SumaryAllProduct(true,search)">
                  <v-list-item-title>กรองสินค้าทั้งหมด</v-list-item-title>
                </v-list-item> -->
                <v-list-item @click="CancelOrder()">
                  <v-list-item-title>ยกเลิกออเดอร์ที่เลือก</v-list-item-title>
                </v-list-item>
                <!-- <v-list-item @click="CancelAllOrder(search)">
                  <v-list-item-title
                    ><span class="color-red"
                      >ยกเลิกออเดอร์ทั้งหมด</span
                    ></v-list-item-title
                  >
                </v-list-item> -->
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="6" class="text-right">
            <span>ทั้งหมด {{ TotalResult }} รายการ</span>
          </v-col>
        </v-row>

        <v-data-table
          v-model="selectedOrder"
          show-select
          :headers="header"
          :items="dataset"
          :items-per-page="9999999999"
          :mobile-breakpoint="0"
          hide-default-footer
          class="packhai-border-table"
        >
          <template v-slot:item.id="{ item }">
            <a @click="ShowDetailOrder(item.token)">{{ FormatOrder(item.id) }}</a>
          </template>
          <template v-slot:item.createdDatetime="{ item }">
            {{ formatDatetime(item.createdDatetime) }}
          </template>
          <template v-slot:item.receiverName="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                  <span>{{ item.receiverName }}</span>
                </div>
              </template>
              <span>
                <span>{{ item.receiverName }}</span>
              </span>
            </v-tooltip>
          </template>
          <template v-slot:item.startPackingDate="{ item }">
            {{ formatDatetime(item.startPackingDate) }}
          </template>
          <template v-slot:item.packedDate="{ item }">
            {{ formatDatetime(item.packedDate) }}
          </template>
          <template v-slot:item.channelName="{ item }">
            <v-avatar size="25px" tile>
              <v-img
                v-if="item.channelLogo != null && item.channelLogo != ''"
                :src="item.channelLogo"
              />
            </v-avatar>
          </template>
          <template v-slot:item.expressCompanyName="{ item }">
            <img
              v-if="item.expressCompanyLogo != null && item.expressCompanyLogo != ''"
              width="60px"
              :src="item.expressCompanyLogo"
            />
          </template>
          <template v-slot:item.shippingStatusName="{ item }">
            <span v-if="item.shippingStatusID == 10" style="color: black">{{
              item.shippingStatusName
            }}</span>
            <span v-if="item.shippingStatusID == 20" style="color: #e40078">{{
              item.shippingStatusName
            }}</span>
            <span v-if="item.shippingStatusID == 30" style="color: #ff7ac0">{{
              item.shippingStatusName
            }}</span>
            <span v-if="item.shippingStatusID == 40" style="color: orange">{{
              item.shippingStatusName
            }}</span>
            <span v-if="item.shippingStatusID == 50" style="color: blue">{{
              item.shippingStatusName
            }}</span>
            <span v-if="item.shippingStatusID == 60" style="color: #9acd32">{{
              item.shippingStatusName
            }}</span>
            <span v-if="item.shippingStatusID == 70" style="color: green">{{
              item.shippingStatusName
            }}</span>
            <span v-if="item.shippingStatusID == 90" style="color: red">{{
              item.shippingStatusName
            }}</span>
          </template>
          <template v-slot:item.countProduct="{ item }">
            {{ formatMoney(item.countProduct) }}
          </template>
          <template v-slot:item.isCOD="{ item }">
            <span v-if="!item.isCOD"
              ><v-chip color="primary" dark outlined>ธรรมดา</v-chip></span
            >
            <span v-else><v-chip class="CODCenter" color="error" dark>COD</v-chip></span>
          </template>
        </v-data-table>
        <v-row class="mt-2">
          <v-col cols="2" lg="1"> </v-col>
          <v-col cols="8" lg="10" @click="ChangePage()">
            <v-pagination
              v-if="pageLength > 1 && dataset.length > 0"
              class="text-center pa-2 ma-0"
              v-model="page"
              :length="pageLength"
              :total-visible="pageTotalVisible"
              color="info"
            ></v-pagination>
          </v-col>
          <v-col cols="2" class="text-right" lg="1">
            <v-select
              v-model="limits"
              label="จำนวนแสดง"
              :items="[10, 20, 50, 100, 500, 1000]"
              @change="LimitChange()"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- เปลี่ยนหน้า -->

    <!-- Approve Progress bar -->
    <v-dialog
      v-model="approveDialog"
      scrollable
      :max-width="approveDialogWidth"
      persistent
    >
      <v-card elevation="1">
        <v-card-title class="height-57">
          <v-row>
            <v-col cols="6">
              <span><h5>ยกเลิกออร์เดอร์</h5></span>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn
                icon
                @click="
                  (approveDialog = false),
                    (approving = false),
                    (approvingPercent = 0),
                    (isApproveDisabled = false)
                "
                ><v-icon color="danger">mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-progress-linear v-model="approvingPercent" color="success" height="25">
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
          <br />
          <v-row>
            <v-col cols="12" align="center">
              <v-btn
                :elevation="1"
                color="primary"
                class="pa-2"
                :disabled="isApproveDisabled"
                @click="ApproveChangeStatusOrder()"
              >
                <v-icon size="14" class="mr-1 pt-1">fa-solid fa-check</v-icon>
                <span class="fn-14"><b> ยืนยัน</b></span>
              </v-btn>

              <v-btn
                :elevation="1"
                color="error"
                class="pa-2 ml-4"
                @click="
                  (approveDialog = false),
                    (approving = false),
                    (approvingPercent = 0),
                    (isApproveDisabled = false)
                "
              >
                <v-icon size="14" class="mr-1 pt-1">fa-solid fa-close</v-icon>
                <span class="fn-14"><b> ยกเลิก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/website/components/Loading";
import {
  shopService_dotnet,
  orderService_dotnet,
  branchService_dotnet,
  logisticService_dotnet,
} from "@/website/global";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import {
  FormatOrder,
  DateNow,
  formatMoney,
  formatMoneyIsDecimal,
  Trim_value,
  Trim_rules,
  formatDatetime,
  length60,
  ChangeOrderId,
  GenPA,
} from "@/website/global_function";
import { nextLine } from "pdf-lib";
export default {
  components: {
    Loading,
  },
  data: (vm) => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    // page
    TotalResult: 0,
    page_loading: true,
    loading: false,
    OrderPerPage: 20,
    ExcelName: null,
    BranchID: localStorage.getItem("Branch_BranchID"),
    // ค้นหา
    search: {
      shippingStatusList: [{ id: 0, name: "--ทั้งหมด--" }],
      shippingStatus: { id: 45, name: "รอแพ็คสินค้า และ กำลังแพ็คสินค้า" },
      shopList: [{ id: 0, name: "--ทั้งหมด--" }],
      shop: { id: 0, name: "--ทั้งหมด--" },
      channelSourceList: [{ id: 0, name: "--ทั้งหมด--" }],
      channelSource: { id: 0, name: "--ทั้งหมด--" },
      expressCompanyList: [{ id: -1, name: "--ทั้งหมด--" }],
      expressCompany: { id: -1, name: "--ทั้งหมด--" },
      MarketPlaceSourceList: [],
      MarketPlaceSource: [],
      orderId: null,
      trackingNo: null,
      referenceNo: null,
      packName: null,
      recipientName: null,
      warehouse: null,
      warehouseIDList: [],
    },
    // ตามวันที่
    startDate: null,
    dateStart: null,
    menuStartDate: false,
    endDate: null,
    dateEnd: null,
    menuEndDate: false,
    disableDate: false,
    // วันที่แพ็ค
    startDate2: null,
    dateStart2: null,
    menuStartDate2: false,
    endDate2: null,
    dateEnd2: null,
    menuEndDate2: false,
    disableDate2: false,

    header: [
      // { text: 'ลบ',align: 'center',sortable: false,value: 'delete',width: '60px'},
      { text: "ลำดับ", align: "center", sortable: false, value: "no", width: "80px" },
      { text: "เลขออเดอร์", align: "left", sortable: false, value: "id", width: "110px" },
      {
        text: "วันที่สร้าง",
        align: "center",
        sortable: false,
        value: "createdDatetime",
        width: "160px",
      },
      { text: "ร้านค้า", sortable: false, value: "shopName", width: "200px" },
      {
        text: "ช่องทาง",
        align: "center",
        sortable: false,
        value: "channelName",
        width: "90px",
      },
      {
        text: "ขนส่ง",
        align: "center",
        sortable: false,
        value: "expressCompanyName",
        width: "90px",
      },

      {
        text: "ประเภท",
        align: "center",
        sortable: false,
        value: "isCOD",
        width: "130px",
      },
      {
        text: "สถานะ",
        align: "center",
        sortable: false,
        value: "shippingStatusName",
        width: "140px",
      },
      {
        text: "เลขอ้างอิง",
        align: "center",
        sortable: false,
        value: "referenceNumber",
        width: "110px",
      },
      {
        text: "คนแพ็ค",
        align: "center",
        sortable: false,
        value: "packedByStaffBranchName",
        width: "90px",
      },
      {
        text: "เริ่มแพ็ค",
        align: "center",
        sortable: false,
        value: "startPackingDate",
        width: "160px",
      },
      {
        text: "แพ็คเสร็จ",
        align: "center",
        sortable: false,
        value: "packedDate",
        width: "160px",
      },
      {
        text: "Tracking",
        align: "center",
        sortable: false,
        value: "trackingNo",
        width: "110px",
      },
      {
        text: "จำนวน SKU",
        align: "center",
        sortable: false,
        value: "skuCount",
        width: "110px",
      },
      {
        text: "จำนวนชิ้น",
        align: "center",
        sortable: false,
        value: "productCount",
        width: "110px",
      },
      {
        text: "น้ำหนัก",
        align: "center",
        sortable: false,
        value: "weight",
        width: "90px",
      },
      {
        text: "ขนาด",
        align: "center",
        sortable: false,
        value: "boxSize",
        width: "110px",
      },
      {
        text: "กล่อง",
        align: "center",
        sortable: false,
        value: "boxName",
        width: "100px",
      },
      {
        text: "ผู้รับ",
        align: "left",
        sortable: false,
        value: "receiverName",
        width: "200px",
      },
    ],
    selectedOrder: [],
    dataset: [],
    total: 0,
    warehouseList_filter: [],
    // Export Excel
    loadingExcel: 0,
    datasetExcel: [],
    headerExcel: [
      { label: "เลขออเดอร์", field: "id" },
      { label: "สถานะออเดอร์", field: "shippingStatusName" },
      { label: "วันที่สร้าง", field: "createdDatetime" },
      { label: "จำนวนสินค้าทั้งหมด", field: "countProduct" },
      { label: "ร้านค้า", field: "shopName" },
      { label: "ช่องทาง", field: "channelName" },
      { label: "ขนส่ง", field: "expressCompanyName" },
      { label: "เวลาที่ส่งให้ขนส่ง", field: "sendToExpressDatetime" },
      { label: "สถานะจัดส่ง", field: "parcelStatusName" },
      { label: "เลขแทรกกิ้ง", field: "trackingNo" },
      { label: "เบอร์ผู้ส่ง", field: "senderPhone" },
      { label: "ชื่อผู้ส่ง", field: "senderName" },
      { label: "ที่อยู่ผู้ส่ง", field: "senderAddress" },
      { label: "ตำบลผู้ส่ง", field: "senderSubDistrict" },
      { label: "อำเภอผู้ส่ง", field: "senderDistrict" },
      { label: "จังหวัดผู้ส่ง", field: "senderProvince" },
      { label: "รหัสไปรษณีผู้ส่ง", field: "senderPostcode" },
      { label: "เบอร์ผู้รับ", field: "receiverPhone" },
      { label: "ชื่อผู้รับ", field: "receiverName" },
      { label: "ที่อยู่ผู้รับ", field: "receiverAddress" },
      { label: "ตำบลผู้รับ", field: "receiverSubDistrict" },
      { label: "อำเภอผู้รับ", field: "receiverDistrict" },
      { label: "จังหวัดผู้รับ", field: "receiverProvince" },
      { label: "รหัสไปรษณีผู้รับ", field: "receiverPostcode" },
      { label: "อีเมลผู้รับ", field: "receiverEmail" },
      { label: "เฟสบุ๊คผู้รับ", field: "receiverFacebook" },
      { label: "ไลน์ผู้รับ", field: "receiverLine" },
      { label: "ธนาคาร", field: "shopBank" },
      { label: "จำนวนเงิน", field: "shopBankAmount" },
      { label: "รายการโอน", field: "dataPayment" },
      { label: "สร้างโดย", field: "createdByStaffShopName" },
      { label: "พนักงานขาย", field: "salemanName" },
      { label: "ประเภท", field: "isCOD" },
      { label: "ยอด COD", field: "codAmount" },
      { label: "ค่าบริการ COD", field: "codCharged" },
      { label: "ราคารวม", field: "totalPrice" },
      { label: "ค่าส่ง", field: "deliveryCost" },
      { label: "ค่าขนส่ง", field: "deliveryPrice" },
      { label: "Vat", field: "vat" },
      { label: "ยอด Vat", field: "vatAmount" },
      { label: "ค่าพื้นที่ห่างไกล", field: "remotePrice" },
      { label: "ค่าแพ็ค", field: "packPrice" },
      { label: "ค่ากล่อง", field: "boxPrice" },
      // { label: "ค่ากล่อง+ค่าแพ็ค", field: ""},
      { label: "ค่าบับเบิ้ล", field: "bubblePrice" },
      { label: "ค่าเชื่อมต่อ", field: "vrichTransactionFee" },
      { label: "ส่วนลด", field: "discount" },
      { label: "ส่วนลดออเดอร์", field: "orderDiscount" },
      { label: "รวมทั้งหมด", align: "center", field: "orderTotalAmount" },
      { label: "วันที่แพ็ค", field: "packedDate" },
      { label: "คนแพ็ค", field: "packedByStaffBranchName" },
      // { label: "ขนาดกล่องตอนเลือกแพ็ค", field: ""},
      // { label: "กล่อง", field: ""},
      { label: "น้ำหนัก", field: "weight" },
      { label: "กว้าง", field: "cubic1" },
      { label: "ยาว", field: "cubic2" },
      { label: "สูง", field: "cubic3" },
      { label: "กล่อง", field: "boxName" },
      { label: "เลขอ้างอิง", field: "referenceNumber" },
      { label: "หมายเหตุ", field: "remark" },
      { label: "สินค้า", field: "productName" },
      { label: "prop1", field: "prop1" },
      { label: "prop1Description", field: "prop1Description" },
      { label: "prop2", field: "prop2" },
      { label: "prop2Description", field: "prop2Description" },
      { label: "SKU", field: "sku" },
      { label: "SerialNo", field: "serialNumber" },
      { label: "Barcode", field: "barcode" },
      { label: "รหัส CF", field: "cfCode" },
      { label: "จำนวนสินค้า", field: "quantity" },
      { label: "ราคาขาย", field: "unitPrice" },
    ],
    // paginations
    page: 1,
    pageTemp: 1,
    pageLength: 1,
    pageTotalVisible: 9,
    limits: 10,
    offsets: 0,
    // Approve
    approveDialog: false,
    isApproveDisabled: false,
    approveDialogWidth: "500px",
    approvingPercent: 0,
    approving: false,
  }),
  watch: {
    async "search.shop"() {
      let setMarketPlace;
      this.search.MarketPlaceSource = [];
      this.search.MarketPlaceSourceList = [];
      console.log(this.search.shop, "testการทำงาน");

      if (this.search.shop.id == 0) {
        setMarketPlace = await this.getMarketPlace(1, null);
        this.search.MarketPlaceSourceList.push(...setMarketPlace);
        console.log(setMarketPlace);
        console.log(
          "this.search.MarketPlaceSourceList",
          this.search.MarketPlaceSourceList
        );
      } else {
        setMarketPlace = await this.getMarketPlace(2, this.search.shop.id);
        this.search.MarketPlaceSourceList.push(...setMarketPlace);
        console.log(setMarketPlace);
        console.log(
          "this.search.MarketPlaceSourceList",
          this.search.MarketPlaceSourceList
        );
      }
      //ID = SHOPID
    },
    async "search.MarketPlaceSource"() {
      console.log("MarketPlaceSource", this.search.MarketPlaceSource);
    },
  },
  computed: {},
  async created() {
    document.title = this.$router.history.current.meta.title;
    this.ExcelName = "OrderExport_" + new Date().toJSON().slice(0, 10);
    await this.GetWarehouse();
    await this.CheckSearchDateTime(this.search.shippingStatus.id);
    await this.Load_Data(this.limits, 0, this.search, 1);
  },
  methods: {
    async SearchOrder(limits, offsets, search, page) {
      this.loading = true;
      // Export Excel
      this.loadingExcel = 0;

      var orderId = null;
      var orderIdList = null;
      var MarketPlace = null;
      if (search.orderId != "" && search.orderId != null) {
        orderIdList = [];
        if (search.orderId.includes(",")) {
          const tempid = search.orderId.split(",");
          for (var item of tempid) {
            if (item.toUpperCase().substr(0, 2) == "PA") {
              orderIdList.push(parseInt(item.substr(2)));
            } else {
              orderIdList.push(parseInt(item));
            }
          }
        } else {
          if (search.orderId.toUpperCase().substr(0, 2) == "PA") {
            orderIdList.push(parseInt(search.orderId.substr(2)));
          } else {
            orderIdList.push(parseInt(search.orderId));
          }
        }
      }
      if (search.MarketPlaceSource.length > 0) {
        MarketPlace = search.MarketPlaceSource.map((m) => m.uniqueID);
        console.log("MarketPlace", MarketPlace);
      }

      let response = await axios.post(
        orderService_dotnet + "Order/search-order-list-for-warehouse",
        {
          branchId: localStorage.getItem("Branch_BranchID"),
          shippingStatusIdList:
            search.shippingStatus.id == 0
              ? null
              : search.shippingStatus.id == 45
              ? [30, 40]
              : [search.shippingStatus.id],
          dateFrom: this.disableDate
            ? null
            : this.startDate != null
            ? new Date(this.startDate + " 07:00:00").toISOString()
            : null,
          dateTo: this.disableDate
            ? null
            : this.endDate != null
            ? new Date(this.endDate + " 07:00:00").toISOString()
            : null,
          packDateFrom: this.disableDate2
            ? null
            : this.startDate2 != null
            ? new Date(this.startDate2 + " 07:00:00").toISOString()
            : null,
          packDateTo: this.disableDate2
            ? null
            : this.endDate2 != null
            ? new Date(this.endDate2 + " 07:00:00").toISOString()
            : null,
          shopId: search.shop.id == 0 ? null : search.shop.id,
          channelSourceId: search.channelSource.id == 0 ? null : search.channelSource.id,
          expressCompanyId:
            search.expressCompany.id == -1 ? null : search.expressCompany.id,
          orderIdList: orderIdList,
          packName:
            search.packName == "" || search.packName == null ? null : search.packName,
          trackingNo:
            search.trackingNo == "" || search.trackingNo == null
              ? null
              : search.trackingNo,
          referenceNumber:
            search.referenceNo == "" || search.referenceNo == null
              ? null
              : search.referenceNo,
          receivername:
            search.recipientName == "" || search.recipientName == null
              ? null
              : search.recipientName,
          skip: offsets,
          take: limits,
          warehouseIDList:
            search.warehouse.id == 0 ? search.warehouseIDList : [search.warehouse.id],
          uniqueChannelID: MarketPlace,
        },
        { headers: this.header_token }
      );
      this.loading = false;
      if (response.status == 200) {
        this.page = page;
        this.pageTemp = page;
        this.offsets = offsets;
        this.dataset = response.data.data;
        this.total = response.data.resultCount;
        this.TotalResult = response.data.resultCount;
        this.pageLength = Math.ceil(this.total / this.limits);
        if (this.pageLength <= 0) {
          this.pageLength = 1;
        }
      } else {
        this.AlertError();
      }
    },
    async LimitChange() {
      await this.SearchOrder(this.limits, 0, this.search, 1);
    },
    async Load_Data(limits, offsets, search, page) {
      await axios
        .all([
          axios.post(
            shopService_dotnet + "Shop/get-shop-list",
            {
              shopType: 1,
              branchId: localStorage.getItem("Branch_BranchID"),
            },
            { headers: this.header_token }
          ),

          axios.post(
            orderService_dotnet + "Order/get-shipping-status",
            {},
            { headers: this.header_token }
          ),

          axios.post(
            branchService_dotnet + "Branch/get-channelsource-branch",
            {
              branchId: localStorage.getItem("Branch_BranchID"),
            },
            { headers: this.header_token }
          ),

          axios.post(
            logisticService_dotnet + "Logistic/get-express-list",
            {
              branchId: localStorage.getItem("Branch_BranchID"),
            },
            { headers: this.header_token }
          ),

          axios.post(
            orderService_dotnet + "Order/search-order-list-for-warehouse",
            {
              branchId: localStorage.getItem("Branch_BranchID"),
              shippingStatusIdList:
                search.shippingStatus.id == 0
                  ? null
                  : search.shippingStatus.id == 45
                  ? [30, 40]
                  : [search.shippingStatus.id],
              dateFrom: this.disableDate
                ? null
                : this.startDate != null
                ? new Date(this.startDate + " 07:00:00").toISOString()
                : null,
              dateTo: this.disableDate
                ? null
                : this.endDate != null
                ? new Date(this.endDate + " 07:00:00").toISOString()
                : null,
              packDateFrom: this.disableDate2
                ? null
                : this.startDate2 != null
                ? new Date(this.startDate2 + " 07:00:00").toISOString()
                : null,
              packDateTo: this.disableDate2
                ? null
                : this.endDate2 != null
                ? new Date(this.endDate2 + " 07:00:00").toISOString()
                : null,
              shopId: search.shop.id == 0 ? null : search.shop.id,
              channelSourceId:
                search.channelSource.id == 0 ? null : search.channelSource.id,
              expressCompanyId:
                search.expressCompany.id == -1 ? null : search.expressCompany.id,
              orderIdList: null,
              packName:
                search.packName == "" || search.packName == null ? null : search.packName,
              trackingNo:
                search.trackingNo == "" || search.trackingNo == null
                  ? null
                  : search.trackingNo,
              referenceNumber:
                search.referenceNo == "" || search.referenceNo == null
                  ? null
                  : search.referenceNo,
              receivername:
                search.recipientName == "" || search.recipientName == null
                  ? null
                  : search.recipientName,
              skip: offsets,
              take: limits,
              warehouseIDList:
                search.warehouse.id == 0 ? search.warehouseIDList : [search.warehouse.id],
            },
            { headers: this.header_token }
          ),

          axios.get(
            orderService_dotnet + "Order/get-common-channel",
            {},
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread(
            (
              shopList,
              shippingStatusList,
              channelSourceList,
              branchExpressList,
              orderList,
              getMarketPlaceList
            ) => {
              if (
                shopList.status == 200 &&
                shippingStatusList.status == 200 &&
                channelSourceList.status == 200 &&
                branchExpressList.status == 200 &&
                orderList.status == 200 &&
                getMarketPlaceList.status == 200
              ) {
                // channelSourceList
                for (var k in channelSourceList.data) {
                  channelSourceList.data[k].name = channelSourceList.data[k].channelName;
                  this.search.channelSourceList.push(channelSourceList.data[k]);
                }
                // expressCompanyList

                this.search.expressCompanyList = branchExpressList.data.filter(
                  (x) => x.isShowInPackForm == true || x.id == 14
                );
                this.search.expressCompanyList.splice(0, 0, {
                  id: -1,
                  name: "--ทั้งหมด--",
                });
                this.search.expressCompany = {
                  id: -1,
                  name: "--ทั้งหมด--",
                };
                // list shop
                if (shopList.data.result.length > 0) {
                  for (var i in shopList.data.result) {
                    this.search.shopList.push({
                      id: shopList.data.result[i].shop.id,
                      name: shopList.data.result[i].shop.name,
                    });
                  }
                }
                // list shippingStatus
                if (shippingStatusList.data.length > 0) {
                  for (var j in shippingStatusList.data) {
                    this.search.shippingStatusList.push({
                      id: shippingStatusList.data[j].id,
                      name: shippingStatusList.data[j].name,
                    });
                  }
                }
                this.search.shippingStatusList.splice(5, 0, this.search.shippingStatus);

                // order list
                this.dataset = orderList.data.data;
                this.total = orderList.data.resultCount;
                this.TotalResult = orderList.data.resultCount;
                this.pageLength = Math.ceil(this.total / this.limits);
                if (this.pageLength <= 0) {
                  this.pageLength = 1;
                }

                this.search.MarketPlaceSourceList.push(...getMarketPlaceList.data.items);

                this.page_loading = false;
              } else {
                this.AlertError();
              }
            }
          )
        );
    },

    //ส่วนของAPI
    async getMarketPlace(marketPlaceType, shopID) {
      // marketPlaceType 1 = ทั้งหมด
      //                 2 = จาก ShopID

      if (marketPlaceType == 1) {
        //เรียก MarketPlaceID ทัั้งหมด
        return await axios
          .get(orderService_dotnet + "Order/get-common-channel", {
            headers: this.header_token,
          })
          .then((res) => {
            return res.data.items;
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else if (marketPlaceType == 2) {
        //เรียก MarketPlaceID จาก SHOPID
        return await axios
          .get(orderService_dotnet + "Order/get-shop-all-channel?ShopID=" + shopID, {
            headers: this.header_token,
          })
          .then((res) => {
            return res.data.items;
          })
          .catch((error) => {
            console.error("Error", error);
          });
      }
      return null;
    },

    // เรียกใช้

    SumaryProduct(search) {
      if (this.selectedOrder.length == 0) {
        this.AlertWarning("เลือกออเดอร์");
        return;
      }
      this.SumaryAllProduct(false, search);
    },
    async SumaryAllProduct(All, search) {
      this.loading = true;
      var orderIdList = null;
      if (!All) {
        orderIdList = [];
        for (var i of this.selectedOrder) {
          orderIdList.push(i.id);
        }
      }

      let response = await axios.post(
        orderService_dotnet + "Order/sumaryproduct",
        {
          orderIdList: orderIdList,
          branchId: localStorage.getItem("Branch_BranchID"),
          shippingStatusIdList:
            search.shippingStatus.id == 0
              ? null
              : search.shippingStatus.id == 45
              ? [30, 40]
              : [search.shippingStatus.id],
          dateFrom: this.disableDate
            ? null
            : this.startDate != null
            ? new Date(this.startDate + " 07:00:00").toISOString()
            : null,
          dateTo: this.disableDate
            ? null
            : this.endDate != null
            ? new Date(this.endDate + " 07:00:00").toISOString()
            : null,
          packDateFrom: this.disableDate2
            ? null
            : this.startDate2 != null
            ? new Date(this.startDate2 + " 07:00:00").toISOString()
            : null,
          packDateTo: this.disableDate2
            ? null
            : this.endDate2 != null
            ? new Date(this.endDate2 + " 07:00:00").toISOString()
            : null,
          shopId: search.shop.id == 0 ? null : search.shop.id,
          channelSourceId: search.channelSource.id == 0 ? null : search.channelSource.id,
          expressCompanyId:
            search.expressCompany.id == 0 ? null : search.expressCompany.id,
          packName:
            search.packName == "" || search.packName == null ? null : search.packName,
          trackingNo:
            search.trackingNo == "" || search.trackingNo == null
              ? null
              : search.trackingNo,
          referenceNumber:
            search.referenceNo == "" || search.referenceNo == null
              ? null
              : search.referenceNo,
          receivername:
            search.recipientName == "" || search.recipientName == null
              ? null
              : search.recipientName,
        },
        { headers: this.header_token }
      );
      this.loading = false;
      if (response.status == 200) {
        console.log(response.data);
      } else {
        this.AlertError();
      }
    },
    async CancelOrder() {
      this.approveDialog = false;
      this.approving = false;
      this.approvingPercent = 0;
      this.isApproveDisabled = false;
      if (this.selectedOrder.length == 0) {
        this.AlertWarning("เลือกออเดอร์");
        return;
      }
      // Swal.fire({
      //   position: "top",
      //   icon: "warning",
      //   text: "ยืนยันการยกเลิก",
      //   confirmButtonText: "ยืนยัน",
      //   cancelButtonText: "ยกเลิก",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor:  "#d33",
      //   allowOutsideClick: false,
      // }).then(async (result) => {

      // if (result.value) {
      this.approveDialog = true;

      // }
      // });
    },
    async GetWarehouse() {
      let response = await axios.get(
        branchService_dotnet + "Branch/get-warehouse-list?BranchID=" + this.BranchID,
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.search.warehouseIDList = [];
        this.warehouseList_filter = [];
        if (response.data.warehouseList.length > 0) {
          if (response.data.warehouseList.length > 1) {
            this.warehouseList_filter = [{ id: 0, name: "--ทั้งหมด--" }];
          }
          for (var i in response.data.warehouseList) {
            this.search.warehouseIDList.push(response.data.warehouseList[i].id);
            this.warehouseList_filter.push({
              id: response.data.warehouseList[i].id,
              name: response.data.warehouseList[i].name,
            });
          }
        }
        this.search.warehouse = this.warehouseList_filter[0];
      }
    },
    async CancelAllOrder(search) {
      Swal.fire({
        position: "top",
        icon: "warning",
        text: 'คุณกำลังจะ "ยกเลิกออเดอร์ทั้งหมด"',
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          var orderId = null;
          var orderIdList = null;
          if (search.orderId != "" && search.orderId != null) {
            orderIdList = [];
            orderId = this.ChangeOrderId(search.orderId);
            orderIdList.push(orderId);
          }

          let response = await axios.post(
            orderService_dotnet + "Order/cancel-order-list",
            {
              branchId: localStorage.getItem("Branch_BranchID"),
              shippingStatusIdList:
                search.shippingStatus.id == 0
                  ? null
                  : search.shippingStatus.id == 45
                  ? [30, 40]
                  : [search.shippingStatus.id],
              dateFrom: this.disableDate
                ? null
                : this.startDate != null
                ? new Date(this.startDate + " 07:00:00").toISOString()
                : null,
              dateTo: this.disableDate
                ? null
                : this.endDate != null
                ? new Date(this.endDate + " 07:00:00").toISOString()
                : null,
              packDateFrom: this.disableDate2
                ? null
                : this.startDate2 != null
                ? new Date(this.startDate2 + " 07:00:00").toISOString()
                : null,
              packDateTo: this.disableDate2
                ? null
                : this.endDate2 != null
                ? new Date(this.endDate2 + " 07:00:00").toISOString()
                : null,
              shopId: search.shop.id == 0 ? null : search.shop.id,
              channelSourceId:
                search.channelSource.id == 0 ? null : search.channelSource.id,
              expressCompanyId:
                search.expressCompany.id == 0 ? null : search.expressCompany.id,
              orderIdList: orderIdList,
              packName:
                search.packName == "" || search.packName == null ? null : search.packName,
              trackingNo:
                search.trackingNo == "" || search.trackingNo == null
                  ? null
                  : search.trackingNo,
              referenceNumber:
                search.referenceNo == "" || search.referenceNo == null
                  ? null
                  : search.referenceNo,
              receivername:
                search.recipientName == "" || search.recipientName == null
                  ? null
                  : search.recipientName,
              isDeleteAll: true,
              order_list: orderIdList,
              staff_branch_id: localStorage.getItem("Branch_StaffID"),
            },
            { headers: this.header_token }
          );
          if (response.status == 200) {
            this.AlertSuccess();
            this.selectedOrder = [];
            this.SearchOrder(this.limits, 0, this.search, 1);
          } else {
            this.loading = false;
            this.AlertError();
          }
        }
      });
    },
    async ChangePage() {
      if (this.pageLength != 1 && this.pageTemp != this.page) {
        var offsets = this.page * this.limits - this.limits;
        await this.SearchOrder(this.limits, offsets, this.search, this.page);
        this.pageTemp = this.page;
      }
    },
    ResetSearch() {
      this.search.shippingStatus = { id: 45, name: "รอแพ็คสินค้า และ กำลังแพ็คสินค้า" };
      this.startDate = null;
      this.dateStart = null;
      this.menuStartDate = false;
      this.endDate = null;
      this.dateEnd = null;
      this.menuEndDate = false;
      this.disableDate = false;
      // วันที่แพ็ค
      this.startDate2 = null;
      this.dateStart2 = null;
      this.menuStartDate2 = false;
      this.endDate2 = null;
      this.dateEnd2 = null;
      this.menuEndDate2 = false;
      this.disableDate2 = true;
      this.search.shop = { id: 0, name: "--ทั้งหมด--" };
      this.search.orderId = null;
      this.search.trackingNo = null;
      this.search.referenceNo = null;
      this.search.packName = null;
      this.search.recipientName = null;

      // Export Excel
      this.loadingExcel = 0;
    },
    ShowDetailOrder(orderToken) {
      window.open("/order-detail?token=" + orderToken);
    },
    async ExportExcel() {
      this.datasetExcel = [];
      this.loadingExcel = 0;
      Swal.fire({
        position: "top",
        icon: "warning",
        text: "ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          var limits = 100;
          var offsets = 0;
          this.loadingExcel = 1;
          var total = this.total;
          var round = parseInt(total / limits);

          if (limits * round < total) {
            round = round + 1;
          }

          for (var i = 1; i <= round; i++) {
            var data = await this.GetExportExcel(limits, offsets, this.search);
            for (var j of data) {
              this.datasetExcel.push({
                id: this.GenPA(j.id),
                shippingStatusName: j.shippingStatusName,
                createdDatetime: formatDatetime(j.createdDatetime),
                countProduct: formatMoney(j.countProduct),
                shopName: j.shopName,
                channelName: j.channelName,
                expressCompanyName: j.expressCompanyName,
                isCOD: j.isCOD,
                sendToExpressDatetime: formatDatetime(j.sendToExpressDatetime),
                parcelStatusName: j.parcelStatusName,
                trackingNo: j.trackingNo,
                senderPhone: j.senderPhone,
                senderName: j.senderName,
                senderAddress: j.senderAddress,
                senderSubDistrict: j.senderSubDistrict,
                senderDistrict: j.senderDistrict,
                senderProvince: j.senderProvince,
                senderPostcode: j.senderPostcode,
                receiverPhone: j.receiverPhone,
                receiverName: j.receiverName,
                receiverAddress: j.receiverAddress,
                receiverSubDistrict: j.receiverSubDistrict,
                receiverDistrict: j.receiverDistrict,
                receiverProvince: j.receiverProvince,
                receiverPostcode: j.receiverPostcode,
                receiverEmail: j.receiverEmail,
                receiverFacebook: j.receiverFacebook,
                receiverLine: j.receiverLine,
                shopBank: j.shopBank,
                shopBankAmount: j.shopBankAmount,
                dataPayment: j.dataPayment,
                createdByStaffShopName: j.createdByStaffShopName,
                salemanName: j.salemanName,
                codAmount: formatMoneyIsDecimal(j.codAmount),
                codCharged: formatMoneyIsDecimal(j.codCharged),
                totalPrice: formatMoneyIsDecimal(j.totalPrice),
                deliveryCost: formatMoneyIsDecimal(j.deliveryCost),
                deliveryPrice: formatMoneyIsDecimal(j.deliveryPrice),
                vat: j.vat,
                vatAmount: formatMoneyIsDecimal(j.vatAmount),
                remotePrice: formatMoneyIsDecimal(j.remotePrice),
                packPrice: formatMoneyIsDecimal(j.packPrice),
                boxPrice: formatMoneyIsDecimal(j.boxPrice),
                bubblePrice: formatMoneyIsDecimal(j.bubblePrice),
                vrichTransactionFee: formatMoneyIsDecimal(j.vrichTransactionFee),
                discount: formatMoneyIsDecimal(j.discount),
                orderDiscount: formatMoneyIsDecimal(j.orderDiscount),
                orderTotalAmount: formatMoneyIsDecimal(j.orderTotalAmount),
                packedDate: formatDatetime(j.packedDate),
                packedByStaffBranchName: j.packedByStaffBranchName,
                weight: j.weight,
                cubic1: j.cubic1,
                cubic2: j.cubic2,
                cubic3: j.cubic3,
                referenceNumber: j.referenceNumber,
                remark: j.remark,
                productName: j.productName,
                prop1: j.prop1,
                prop1Description: j.prop1Description,
                prop2: j.prop2,
                prop2Description: j.prop2Description,
                sku: j.sku,
                serialNumber: j.serialNumber,
                barcode: j.barcode,
                cfCode: j.cfCode,
                quantity: j.quantity,
                unitPrice: j.unitPrice,
                boxName: j.boxName,
              });
            }
            this.loadingExcel = parseInt(
              (parseFloat(i) / parseFloat(round)) * parseFloat(100)
            );
            offsets = offsets + limits;
          }
          this.loadingExcel = this.datasetExcel.length == 0 ? 0 : 100;
          document.getElementById("GetExcel").click();
        }
      });
    },
    async GetExportExcel(limits, offsets, search) {
      try {
        var orderId = null;
        var orderIdList = null;
        if (search.orderId != "" && search.orderId != null) {
          orderIdList = [];
          orderId = this.ChangeOrderId(search.orderId);
          if (!isNaN(orderId)) {
            orderIdList.push(orderId);
          }
        }
        var MarketPlace;
        if (search.MarketPlaceSource.length > 0) {
          MarketPlace = search.MarketPlaceSource.map((m) => m.uniqueID);
          console.log("MarketPlace", MarketPlace);
        }
        let response = await axios.post(
          orderService_dotnet + "Order/search-order-list",
          {
            branchId: localStorage.getItem("Branch_BranchID"),
            shippingStatusIdList:
              search.shippingStatus.id == 0
                ? null
                : search.shippingStatus.id == 45
                ? [30, 40]
                : [search.shippingStatus.id],
            dateFrom: this.disableDate
              ? null
              : this.startDate != null
              ? new Date(this.startDate + " 07:00:00").toISOString()
              : null,
            dateTo: this.disableDate
              ? null
              : this.endDate != null
              ? new Date(this.endDate + " 07:00:00").toISOString()
              : null,
            packDateFrom: this.disableDate2
              ? null
              : this.startDate2 != null
              ? new Date(this.startDate2 + " 07:00:00").toISOString()
              : null,
            packDateTo: this.disableDate2
              ? null
              : this.endDate2 != null
              ? new Date(this.endDate2 + " 07:00:00").toISOString()
              : null,
            shopId: search.shop.id == 0 ? null : search.shop.id,
            channelSourceId:
              search.channelSource.id == 0 ? null : search.channelSource.id,
            expressCompanyId:
              search.expressCompany.id == 0 ? null : search.expressCompany.id,
            orderIdList: orderIdList,
            packName:
              search.packName == "" || search.packName == null ? null : search.packName,
            trackingNo:
              search.trackingNo == "" || search.trackingNo == null
                ? null
                : search.trackingNo,
            referenceNumber:
              search.referenceNo == "" || search.referenceNo == null
                ? null
                : search.referenceNo,
            receivername:
              search.recipientName == "" || search.recipientName == null
                ? null
                : search.recipientName,
            isExcel: true,
            uniqueChannelID: MarketPlace,
            isNeedResultCount:false,
            skip: offsets,
            take: limits,
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          return response.data.exportOrderDetail;
        } else {
          return [];
        }
      } catch (e) {
        console.log("error");
      }
    },
    CheckSearchDateTime(id) {
      if (id == 0 || id == 70) {
        this.disableDate = false;
        this.disableDate2 = false;
      } else if (id == 10 || id == 20 || id == 30 || id == 40 || id == 45 || id == 90) {
        this.disableDate = false;
        this.disableDate2 = true;

        this.startDate = null;
        this.dateStart = null;
        this.endDate = null;
        this.dateEnd = null;
        this.startDate2 = null;
        this.dateStart2 = null;
        this.endDate2 = null;
        this.dateEnd2 = null;
      } else if (id == 50 || id == 60) {
        this.disableDate = true;
        this.disableDate2 = true;
      }
    },
    CheckDateTime(index, startDate, endDate) {
      if (startDate != null && endDate != null) {
        var PstartDate = startDate;
        var PendDate = endDate;
        if (new Date(startDate) > new Date(endDate)) {
          PstartDate = endDate;
          PendDate = startDate;
        }
        startDate = PstartDate;
        endDate = PendDate;
      }
      if (index == 0) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.dateStart = this.formatDate(startDate);
        this.dateEnd = this.formatDate(endDate);
      }
      if (index == 1) {
        this.startDate2 = startDate;
        this.endDate2 = endDate;
        this.dateStart2 = this.formatDate(startDate);
        this.dateEnd2 = this.formatDate(endDate);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async ApproveChangeStatusOrder() {
      try {
        this.isApproveDisabled = true;
        var currentTransaction = 1;
        var TotalTransaction = this.selectedOrder.length + 1;
        this.approvingPercent = parseInt((currentTransaction / TotalTransaction) * 100);

        var currentOrder = this.selectedOrder.map((m) => m.id);

        for (let item of currentOrder) {
          var IsPass = false;
          while (!IsPass) {
            let approved = await axios.post(
              orderService_dotnet + "Order/change-order-status",
              {
                orderID: item,
                shippingStatusID: 90,
                staffBranchId: localStorage.getItem("Branch_StaffID"),
                staffShopID: localStorage.getItem("staff_id"),
              },
              { headers: this.header_token }
            );
            if (approved.status == 200 && approved.data.status == "success") {
              currentTransaction = currentTransaction + 1;
              this.approvingPercent = parseInt(
                (currentTransaction / TotalTransaction) * 100
              );
              IsPass = true;
            }
          }
        }

        this.AlertSuccess("ยกเลิกออเดอร์สำเร็จ");
        setTimeout(() => {
          // window.location.reload();
          this.SearchOrder(this.limits, 0, this.search, 1);
          this.selectedOrder = [];
          this.approveDialog = false;
        }, 1000);
      } catch (e) {
        this.isApproveDisabled = false;
        this.approvingPercent = 0;
        Swal.fire({
          icon: "warning",
          title: "ไม่สามารถทำรายการได้",
          text: "กรุณาทำรายการอีกครั้ง",
        });
      }
    },

    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    FormatOrder,
    DateNow,
    formatMoney,
    formatMoneyIsDecimal,
    Trim_value,
    Trim_rules,
    formatDatetime,
    length60,
    ChangeOrderId,
    AlertSuccess,
    AlertWarning,
    AlertError,
    GenPA,
  },
};
</script>

<style scoped>
>>> .he-40 {
  height: 40px !important;
}
.box-table {
  border-top: 1px solid #f5f5f5;
}
.outer-box-table {
  border: 0.5px solid #f5f5f5;
}
>>> .bg-color {
  background-color: #f2f4f8 !important;
}
>>> .v-select__slot {
  height: 40px;
}
.area-select {
  margin-left: 10px;
  margin-right: 10px;
  width: 280px;
}
</style>
